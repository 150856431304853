.terms-container {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	padding: 10rem;
	width: 100%;
}

.terms-container h1 {
	color: var(--darkColor);
	padding-bottom: 2rem;
	text-align: center;
}
.terms-container > p {
	text-align: left;
	width: 100%;
	max-width: 1200px;
	padding-bottom: 1rem;
}

.text-pad {
	padding: 0.5rem 2rem;
	/* max-width: 700px; */
}

.text-pad ul {
	padding: 0.3rem 2rem;
}
.text-pad ul li {
	list-style: lower-alpha;
	padding: 0.3rem 1rem;
}
.text-pad ul li ul li {
	list-style: lower-roman;
}
.text-pad span {
	display: block;
}
.text-pad .list-none {
	list-style: none;
}

.text-pad ul li p {
	padding: 0.3rem 0;
}

.list-cap {
	list-style: upper-alpha;
}

/* .terms-container:nth-of-type(2) {
} */
@media (max-width: 1440px) {
}
@media (max-width: 1024px) {
	.terms-container {
		padding: 5rem;
	}
}
@media (max-width: 768px) {
	.terms-container {
		padding: 5rem 1rem 5rem 1rem;
	}

	.terms-container > h1 {
		padding-bottom: 1rem;
		font-size: 3rem;
	}

	.terms-container p b {
		font-size: 1rem;
	}

	.text-pad {
		padding: 0.2rem 0.8rem;
	}

	.text-pad ul {
		padding: 0.1rem 1rem;
	}
	.text-pad ul li {
		list-style: lower-alpha;
		padding: 0.1rem 0.6rem;
	}
}
@media (max-width: 480px) {
	.terms-container {
		padding: 5rem 0.5rem 0.5rem 0.5rem;
	}
	.terms-container h1 {
		padding-bottom: 1rem;
		font-size: 1rem;
	}
}
