/* Animation in the Header  */
.animationHeader1 {
  position: absolute;
  transition: 0.5s ease-out;
  left: 0;
  top: 0;
  transform: scale(1.1);
}

.animationHeader2 {
  position: absolute;
  transition: 0.5s ease-out;
  right: 0;
  top: 30px;
  transform: scale(1.1);
}

.hero-hover:hover .animationHeader2 {
  transition: 0.5s ease-in;
  right: -100px;
}

.hero-hover:hover .animationHeader1 {
  transition: 0.5s ease-in;
  left: -100px;
}
