.signup-background {
    background: linear-gradient(263deg,rgba(247,233,177,.1),rgba(214,255,169,.02),rgba(214,255,169,.02),rgba(247,233,177,.1));
    background-size: 400% 400%;
    padding-top: 6rem;
    padding-bottom: 4rem;
}

.bg-image {
    position: absolute;
    width: 100%;
    bottom: 28%;
    z-index: -10;
}


@media (max-width: 960px) {
    .signup-background {
        padding: 1rem 1rem !important;
    }
    .bg-image {
        display: none;
    }
}

@media only screen and (min-width: 528px) and (max-width: 960px) {
    .signUp {
        height: 550px !important;
    }
  }


