.community {
  height: fit-content;
  padding: 2rem 0;
}

.column-center {
  margin-top: 5%;
}

.community-container {
  max-width: 1200px;
  position: relative;
  padding: 16px;
}

.community-container header {
  text-align: center;
  /* max-width: 700px; */
  padding: 0 16px;
}

.community-container p {
  width: 100%;
  padding-bottom: 1rem;
  font-size: 1.2rem;
  /* line-height: 1.6rem; */
  /* text-align: justify; */
}
.community > h2 {
  font-size: 2.5rem;
  color: var(--darkColor);
  text-align: center;
}
aside,
main {
  padding: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

main > p {
  padding-top: 16px;
  text-align: center;
}

.community-main-img {
  background: url("https://web4.kinetx.co/newImages/yogaImg.png") center no-repeat;
  background-size: contain;
  min-height: 400px;
}

.community-form {
  padding: 16px 0;
  gap: 16px;
}

.community-form1,
.community-form2 {
  gap: 16px;
  width: 100%;
}

.community-form-item {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.form-error {
  font-size: 12px;
}

@media (max-width: 1440px) {
}
@media (max-width: 1024px) {
  .community-container {
    flex-direction: column-reverse;
  }
  .community-container p {
    font-size: 1.3rem;
    line-height: 1.8rem;
  }
  .community-container > h1 {
    font-size: 2rem;
  }
  .community-container p {
    font-size: 1.2rem;
  }
}
@media (max-width: 768px) {
  .community-container p {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  .community-container > h1 {
    font-size: 2em;
  }

  .community-container > p {
    font-size: 1em;
  }

  .community-form {
    flex-wrap: wrap;
  }
}
@media (max-width: 480px) {
  .community-container p {
    font-size: 1rem;
    line-height: 1.2rem;
  }
  .community-main-img {
    background-size: contain;
    width: 100%;
    /* min-height: 350px; */
  }
  .community-form1,
  .community-form2 {
    flex-direction: column;
  }
  .community-form-item {
    /* width: 100%; */
    align-items: center;
  }
  .community-form-item p {
    /* width: 100%; */
    align-items: flex-start;
  }
  .form-error {
    width: 100%;
  }
  aside,
  main {
    padding: 0;
  }

  main > p {
    padding: 0;
  }
}
