.navbar-container {
	position: fixed;
	top: 0;
	left: 0;
	height: 80px;
	width: 100%;
	display: flex;
	align-items: center;
	background: transparent;
	z-index: 10;
}
.navbar-container-white {
	position: fixed;
	top: 0;
	left: 0;
	height: 80px;
	width: 100%;
	display: flex;
	align-items: center;
	background: white;
	z-index: 10;
}
.navbar-container-scroll {
	background-color: rgba(255,255,255, 0.8);
	backdrop-filter: blur(5px) saturate(180%);
	position: fixed;
	top: 0;
	left: 0;
	height: 80px;
	width: 100%;
	display: flex;
	align-items: center;
	z-index: 10;
	box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2), 0 1px 12px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 960px) {
	.navbar-container-scroll {
		position: inherit;
		box-shadow: none !important;
	}
	.navbar-container {
		position: inherit;
		height: 80px;
		width: 100%;
		display: flex;
		align-items: center;
		background: transparent;
		z-index: 10;
	}
}
.navbar {
	display: flex;
	width: 100%;
	max-width: 1550px;
	justify-content: space-between;
	margin: auto;
	padding: 0 2rem;
}
.logo-n-button-container {
	display: flex;
	align-items: center;
	max-width: 200px;
	width: 100%;
}


.logo-n-button-container a {
	align-self: center;
}

.events-kino-btn {
	margin-left: 20px;
	max-height: 37px;
}

.navbar ul {
	list-style: none;
	display: flex;
	justify-content: space-evenly;
}

.kino-text-logo {
	width: 170px;
	display: flex;
	margin-left: 10px;
}

.navbar .guestLinks ul li {
	font-weight: 500;

}
.navbar .guestLinks {
	width: 25%;
	justify-content: flex-end;
	/* padding-right: 8px; */
}

.navbar .userLinks Button a {
	text-decoration: none;
	color: var(--grey);
}
.navbar .userLinks li Button Link {
	text-decoration: none;
	color: red;
}

.navbar ul li {
	align-self: center;
}

.navbar a {
	text-decoration: none;
	color: var(--darkColor);
}

.hamburger {
	color: var(--darkColor);
	padding-right: 2rem;
	display: none;
}

.list-dropdown-content {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	font-size: 1.2rem;
	display: none;
}

.list-dropdown-content a {
	margin-top: 1rem;
}

.burger_list {
	padding-top: 3rem;
	position: absolute;
	top: 5%;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	flex-direction: column;
	align-items: center;
}

.burger_list li {
	margin-top: 1rem;
	color: var(--darkColor);
}

.userLinks {
	min-width: 395px;
}

.userLinks li {
	font-size: 0.9rem;
	/* margin: 10px 10px 10px 0px; */
}
.burger_list li {
	font-size: 0.9rem;
}

.MuiDrawer-root {
	visibility: hidden;
}

@media (max-width: 1440px) {
}

@media (max-width: 1024px) {
	.navbar ul li {
		font-weight: 400;
	}
	.events-kino-btn {
		margin-left: 5px;
	}

	/* .navbar h1 span {
    font-size: 2rem;
  }
  .navbar_logo {
    height: 2.5rem;
    width: 2.5rem;
  } */
	.hamburger {
		display: none !important;
	}
}
/* @media (max-width: 768px) */
@media (max-width: 959px) {
	.navbar .userLinks {
		display: none;
	}
	.navbar .guestLinks {
		display: none;
	}
	.navbar .events-kino-btn {
		display: none;
	}
	.navbar ul li {
		font-weight: 300;
		font-size: 0.8rem;
	}
	/* .navbar h1 {
    font-size: 1.2rem;
    font-weight: 300;
  }

  .navbar h1 span {
    font-size: 2rem;
    padding-right: 0.3rem;
  }
  .navbar_logo {
    height: 2rem;
    width: 2rem;
  } */
	.hamburger {
		color: #ffffff;
		padding-right: 0;
		display: flex !important;
	}
	.MuiDrawer-root {
		visibility: visible;
	}
	.hamburger i {
		color: #ffffff;
	}
	.burger_list {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		text-align: center;
		list-style: none;
		font-size: 1.2rem;
		/* height: 40vh; */
		width: 100%;
	}
	.burger_list li a {
		text-decoration: none;
		color: var(--newColor);
	}
}

@media only screen and (min-width: 960px) and (max-width: 1150px) {
	.logo-n-button-container {
	  width: 110px;
	}
  }

@media (max-width: 1065px) {
	.signInBtn{
		margin-left: 0 !important;
		padding: 9px 20px !important;
	}
	.signUpBtn{
		margin-left: 0 !important;
		padding: 9px 20px !important;
	}
}

@media (max-width: 320px) {
}
.Component-paper-37 {
	-moz-transition: opacity 5ms ease-in-out 0ms, transform 5ms ease-in-out 0ms;
	-webkit-transition: opacity 5ms ease-in-out 0ms, transform 5ms ease-in-out 0ms;
	-moz-transition: opacity 5ms ease-in-out 0ms, transform 5ms ease-in-out 0ms;
	-o-transition: opacity 5ms ease-in-out 0ms, transform 5ms ease-in-out 0ms;
	transition: opacity 5ms ease-in-out 0ms, transform 5ms ease-in-out 0ms !important;
}



@media (max-width: 1091px) {
	.navbar {
		padding: 0 1rem !important;
	}
  }
  