.signin-background {
    background: linear-gradient(263deg,rgba(247,233,177,.1),rgba(214,255,169,.02),rgba(214,255,169,.02),rgba(247,233,177,.1));
    background-size: 400% 400%;
    padding: 6rem 0;
}

.bg-image-signin {
    position: absolute;
    width: 100%;
    bottom: 32%;
    z-index: -100;
}


@media (max-width: 960px) {
    .signin-background {
        padding: 2rem 1rem !important;
    }
    .bg-image-signin {
        display: none;
    }
}




